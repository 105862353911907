import React, { useState } from 'react';
import './Portfolio.css';
import Overlay from './Overlay';
import FooterOverlay from './FooterOverlay'; // Import the new component
import Fib from './pages/Fib';
import Spirals from './pages/Spirals';
import Customizer from './pages/3DP2ACustomizer';
import Blank from './pages/Blank';
import No from './pages/No';
import Sketchverse from './pages/Sketchverse';
import Platool from './pages/Platool';
import DigitalArt from './pages/DigitalArt';
import Conv3rgence from './pages/Conv3rgence';
import P5CreativeCode from './pages/P5CreativeCode';
import CubeStory from './pages/CubeStory';
import Hashbits from './pages/Hashbits';
import WXRSHIP from './pages/WXRSHIP';

const Portfolio = () => {
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [overlayContent, setOverlayContent] = useState(null);

  const handleItemClick = (content) => {
    setOverlayContent(content);
    setOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setOverlayOpen(false);
    setOverlayContent(null);
  };

  return (
    <div>
      <h1 className="watusi">WATUSI</h1>
      <div id="about">
        <p>
          Cross-media Conceptual Artist based in the US. Watusi searches for truth and meaning (usually) using the
          various mediums of digital media. He enjoys exploring new technologies and experimenting with novel ways of
          using them to communicate. Hailing from Southern California and currently based in North Carolina, US.
        </p>
      </div>
      <nav>
        <button>
          <a href="#contact">Contact</a>
        </button>
      </nav>
      <div className="content">
        <div id="home">
          <div id="projects" className="artwork-grid">
            <div className="artwork" onClick={() => handleItemClick(<Fib />)}>
              <a name="fib"></a>
              <img src={process.env.PUBLIC_URL + '/images/fib/33.svg'} alt="Fib" />
              <p>Fib</p>
            </div>
            <div className="artwork" onClick={() => handleItemClick(<Spirals />)}>
              <a name="spirals"></a>
              <img src={process.env.PUBLIC_URL + '/images/spirals/26.svg'} alt="Spirals" />
              <p>Spirals</p>
            </div>
            <div className="artwork" onClick={() => handleItemClick(<Customizer />)}>
              <a name="3dp2acustomizer"></a>
              <video autoPlay="true" muted="true" loop="true">
                <source src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/v0release.mp4'} type="video/mp4" />
              </video>
              <p>3DP2A Customizer</p>
            </div>
            <div className="artwork" >
              <a name="blank" href="https://blankpaper-rust.vercel.app/" target='_blank' >
                <img src={process.env.PUBLIC_URL + '/images/blank/bp-logo-8.png'} alt="Blank" />
              </a>
              <p>Blank</p>
            </div>
            <div className="artwork" onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'red'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
              <a name="no"></a>
              <img src={process.env.PUBLIC_URL + '/images/no/face.gif'} alt="No!" />
              <p>No!</p>
            </div>
            <div className="artwork" onClick={() => handleItemClick(<Platool />)}>
              <a name="platool"></a>
              <video autoPlay="true" muted="true" loop="true">
                <source src={process.env.PUBLIC_URL + '/images/platool/2.mp4'} type="video/mp4" />
              </video>
              <p>Platool</p>
            </div>
            <div className="artwork" onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'red'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>

              <a name="digitalart"></a>
              <video autoPlay="true" muted="true" loop="true">
                <source src={process.env.PUBLIC_URL + '/images/digitalart/2.mp4'} type="video/mp4" />
              </video>
              <p>Digital Art</p>
            </div>

            {/* <div className="artwork" onClick={() => handleItemClick(<P5CreativeCode />)}>
              <a name="p5creativecode"></a>
              <img src="https://via.placeholder.com/150" alt="P5 / Creative Code" />
              <p>P5 / Creative Code</p>
            </div> */}
            {/* <div className="artwork" onClick={() => handleItemClick(<CubeStory />)}>`
              <a name="cubestory"></a>
              <img src="https://via.placeholder.com/150" alt="Cube Story" />
              <p>Cube Story</p>
            </div> */}

          </div>
        </div>

        <div className="flex-item" id="contact" style={{ width: 'calc(100% + 40px)', marginLeft: '-20px' }}>
          <div className="details">
            <p>Last updated: Sep 2 2024</p>
          </div>
          <div className="details">
            <p></p>
            <p><a target="_blank" href="https://x.com/lawwatusi">X: @LawWatusi</a></p>
          </div>
        </div>
      </div>
      <Overlay isOpen={isOverlayOpen} onClose={handleCloseOverlay} content={overlayContent} />
      <FooterOverlay /> {/* Add the FooterOverlay component */}
    </div>
  );
};

export default Portfolio;